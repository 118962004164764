<template>
  <div>
    <b-modal
      :active.sync="searchAvailabilityModal"
      size="lg"
      :ref="id"
      :id="id"
      hide-footer
      scrollable
    >
      <template #modal-title>
        <div>
          <img
            src="/icons/drag.png"
            alt="drag"
            width="25"
            height="25"
            class="mr-2"
          />
          <span>
            {{ title }}
          </span>
        </div>
      </template>
      <template #default="{ close }">
        <div class="d-flex justify-content-center mb-3">
          <b-alert class="my-2 w-50 text-center p-1" show>
            <img src="/icons/drag-02.png" class="mr-2" alt="drag" />
            Arraste e solte os itens na ordem que desejar</b-alert
          >
        </div>
        <draggable
          v-model="draggableArray"
          group="people"
          @start="drag = true"
          @end="drag = false"
          class="mx-3"
        >
          <transition-group>
            <div
              class="
                py-3
                px-2
                d-flex
                justify-content-start
                row
                align-items-center
              "
              v-for="(element, index) in draggableArray"
              :key="'draggable_item_' + index"
            >
              <span class="pl-2 element-row">{{ element.name }}</span>
            </div>
          </transition-group>
        </draggable>

        <div class="d-flex justify-content-end">
          <b-button class="mt-3 mr-2" @click="close()">Cancelar</b-button>
          <b-button class="bg-primary mt-3" @click="closeOrderingById(id)"
            >Ok</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    draggableArray: [],
    searchAvailabilityModal: true,
  }),

  methods: {
    orderById() {
      let idArray = [];
      idArray = this.draggableArray.map((item, index) => {
        return {
          id: item.id,
          order: index + 1,
        };
      });

      this.$emit("order", idArray);
    },
    closeOrderingById(id) {
      this.orderById();
      this.searchAvailabilityModal = false;
      this.$refs[id].hide();
    },
  },

  watch: {
    items: {
      deep: true,
      handler(newValue) {
        this.draggableArray = newValue;
      },
    },
  },

  created() {
    this.items ? (this.draggableArray = this.items) : "";
  },
};
</script>

<style scoped>
.element-row {
  cursor: pointer;
}
div.row:nth-child(odd) {
  background-color: #ececec;
}

div.row:nth-child(even) {
  background-color: #ffffff;
}
</style>
